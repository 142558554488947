<template>
  <div>
    <div class="d-flex">
      <!--  <div class="col-sm-3">
        <v-select
          :items="typeActives"
          v-model="onlyActive"
          dense
     
          :disabled="loading"
          item-text="label"
          item-value="value"
          label="Active Status"
          outlined
        ></v-select>
      </div>
      <div class="col-sm-3">
        <v-text-field
          dense
          outlined
          :disabled="loading"
          hide-details
          label="by Policy Number"
          prepend-inner-icon="mdi-magnify"
          @input="policyNumber=$event.toUpperCase()"
          v-model="policyNumber"
          clearable
        ></v-text-field>
      </div> -->
      <v-spacer></v-spacer>
      <v-btn small :disabled="loading" color="primary" text @click="openFilters"
        ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
      >
      <v-btn
        text
        small
        :disabled="loading"
        color="primary"
        @click="getPayments"
      >
        <v-icon>mdi-refresh</v-icon> Update</v-btn
      >
    </div>   
    <template>
      <v-data-table
        dense
        :headers="headers"
        :loading="loading"
        :items="paymentShow"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="text-body-1">
            {{ item.name }}
          </div>
        </template>

        <template v-slot:[`item.payed_amount`]="{ item }">
          {{ !item.payed_amount ? 0 : item.payed_amount | currency }}
        </template>
        <template v-slot:[`item.due_amount`]="{ item }">
          {{ !item.due_amount ? 0 : item.due_amount | currency }}
        </template>
        <template v-slot:[`item.payed_comision`]="{ item }">
          {{ !item.payed_comision ? 0 : item.payed_comision | currency }}
        </template>
        <template v-slot:[`item.no_payed_comision`]="{ item }">
          {{ !item.no_payed_comision ? 0 : item.no_payed_comision | currency }}
        </template>
        <template v-slot:[`item.discounted_chargeback`]="{ item }">
          <span
            :class="
              item.discounted_chargeback && item.discounted_chargeback != 0
                ? 'red--text'
                : ''
            "
            >{{
              !item.discounted_chargeback
                ? 0
                : item.discounted_chargeback | currency
            }}</span
          >
        </template>
        <template v-slot:[`item.no_discounted_chargeback`]="{ item }">
          <span
            :class="
              item.no_discounted_chargeback &&
              item.no_discounted_chargeback != 0
                ? 'red--text'
                : ''
            "
          >
            {{
              !item.no_discounted_chargeback
                ? 0
                : item.no_discounted_chargeback | currency
            }}</span
          >
        </template>
        <template v-slot:[`item.total_balance`]="{ item }">
          {{ !item.total_balance ? 0 : item.total_balance | currency }}
        </template>


        <template v-slot:[`header.name`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="blue--text" >{{'Totales:' }}</span> 
          </div>
        
        </template>
        <template v-slot:[`header.payed_amount`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="green--text">{{totales.payed_amount|currency }}</span> 
          </div>
        
        </template>
        <template v-slot:[`header.due_amount`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="orange--text">{{totales.due_amount|currency }}</span> 
          </div>
        </template>
        <template v-slot:[`header.payed_comision`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="green--text">{{totales.payed_comision|currency }}</span> 
          </div>
        </template>
        <template v-slot:[`header.no_payed_comision`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="orange--text">{{totales.no_payed_comision|currency }}</span> 
          </div>
        </template>
        <template v-slot:[`header.discounted_chargeback`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="red--text">{{totales.discounted_chargeback|currency }}</span> 
          </div>
        </template>
        <template v-slot:[`header.no_discounted_chargeback`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="red--text">{{totales.no_discounted_chargeback|currency }}</span> 
          </div>
        </template>
        <template v-slot:[`header.total_balance`]="{ header }">
          <div class="d-flex flex-column">
            <span>{{`${header.text}` }}</span> 
            <span class="blue--text">{{totales.total_balance|currency }}</span> 
          </div>
        </template>
      </v-data-table>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import moment from "moment";
export default {
  props: {
    policyY: Number,
    flexp: Boolean,
    yandM: Array,
  },
  data() {
    return {
      filterExpand: false,
      policyYear: null,
      payments: [],
      headers: [
        { text: "Name", width: 200, value: "name", sortable: false },
        {
          text: "Payed Amount",
          width: 130,
          align: "end",
          value: "payed_amount",
          sortable: false,
        },
        {
          text: "Due Amount",
          width: 130,
          align: "end",
          value: "due_amount",
          sortable: false,
        },
        {
          text: "Payed Comision",
          width: 130,
          align: "end",
          value: "payed_comision",
          sortable: false,
        },
        {
          text: "No Payed Comision",
          width: 160,
          align: "end",
          value: "no_payed_comision",
          sortable: false,
        },
        {
          text: "Discounted Chargeback",
          width: 180,
          align: "end",
          value: "discounted_chargeback",
          sortable: false,
        },
        {
          text: "No Discounted Chargeback",
          width: 180,
          align: "end",
          value: "no_discounted_chargeback",
          sortable: false,
        },
        {
          text: "Total Balance",
          width: 180,
          align: "end",
          value: "total_balance",
          sortable: false,
        },
      ],
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
      loading: false,
      yearSelect: Number(moment().format("YYYY")),
      yearsAndMonths: [
        {
          year: Number(moment().format("YYYY")),
          months: [
            "JANUARY",
            "FEBRUARY",
            "MARCH",
            "APRIL",
            "MAY",
            "JUNE",
            "JULY",
            "AUGUST",
            "SEPTEMBER",
            "OCTOBER",
            "NOVEMBER",
            "DECEMBER",
          ],
        },
      ],
      totales: {
        discounted_chargeback: 0,
        due_amount: 0,
        id: null,
        name: "TOTAL",
        no_discounted_chargeback: 0,
        no_payed_comision: 0,
        payed_amount: 0,
        payed_comision: 0,
        total_balance: 0,
      },
    };
  },
  computed: {
    paymentShow() {
      if (this.payments.length == 0) {
        return [];
      }
      const arr = this.payments.filter((p) => p.name != "TOTAL");
      return arr;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getPayments();
      }
    },

    policyY: {
      handler(val) {
        this.policyYear = val;
        this.getPayments();
      },
    },

    flexp: {
      handler(val) {
        if (val == false) {
          this.filterExpand = false;
        }
      },
    },
    /*  Var Months and Years */
    yandM: {
      handler(val, val1) {
        if (val != val1) {
          if (val.length == 0) {
            this.yearSelect = Number(moment().format("YYYY"));
            this.yearsAndMonths[0] = {
              year: Number(moment().format("YYYY")),
              months: [
                "JANUARY",
                "FEBRUARY",
                "MARCH",
                "APRIL",
                "MAY",
                "JUNE",
                "JULY",
                "AUGUST",
                "SEPTEMBER",
                "OCTOBER",
                "NOVEMBER",
                "DECEMBER",
              ],
            };
          } else {
            const y = Number(moment(val[0]).format("YYYY"));
            this.yearSelect = y;
            const m = val.map((v) => {
              return moment(v).format("MMMM").toLocaleUpperCase().toString();
            });
            this.yearsAndMonths[0] = {
              year: y,
              months: m,
            };
          }
          this.getPayments();
        }
      },
    },
  },
  methods: {
    getPayments() {
      this.loading = true;
      let body = {
         yearAndMonths: this.yearsAndMonths[0]
      };

      if (this.policyYear != null) {
        body = { ...body, policyYear: this.policyYear };
      }

      getAPI
        .post("pay-to-nom/getPayToDues", body)
        .then((res) => {
          this.payments = res.data;
          const x = res.data.filter(rdf=>rdf.name=='TOTAL')
          this.totales = x ? x[0]:this.totales
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    openFilters() {
      this.filterExpand = !this.filterExpand;
      this.$emit("openFilter", this.filterExpand);
    },
  },
  async mounted() {
    await this.getPayments();
  },
};
</script>
<style lang="scss" scoped></style>
