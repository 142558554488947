var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","disabled":_vm.loading,"color":"primary","text":""},on:{"click":_vm.openFilters}},[_c('v-icon',[_vm._v("mdi-filter-variant")]),_vm._v(" Filters")],1),_c('v-btn',{attrs:{"text":"","small":"","disabled":_vm.loading,"color":"primary"},on:{"click":_vm.getPayments}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Update")],1)],1),[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"loading":_vm.loading,"items":_vm.paymentShow,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.payed_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.payed_amount ? 0 : item.payed_amount))+" ")]}},{key:"item.due_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.due_amount ? 0 : item.due_amount))+" ")]}},{key:"item.payed_comision",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.payed_comision ? 0 : item.payed_comision))+" ")]}},{key:"item.no_payed_comision",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.no_payed_comision ? 0 : item.no_payed_comision))+" ")]}},{key:"item.discounted_chargeback",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.discounted_chargeback && item.discounted_chargeback != 0
              ? 'red--text'
              : ''},[_vm._v(_vm._s(_vm._f("currency")(!item.discounted_chargeback ? 0 : item.discounted_chargeback)))])]}},{key:"item.no_discounted_chargeback",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.no_discounted_chargeback &&
            item.no_discounted_chargeback != 0
              ? 'red--text'
              : ''},[_vm._v(" "+_vm._s(_vm._f("currency")(!item.no_discounted_chargeback ? 0 : item.no_discounted_chargeback)))])]}},{key:"item.total_balance",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.total_balance ? 0 : item.total_balance))+" ")]}},{key:"header.name",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s('Totales:'))])])]}},{key:"header.payed_amount",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.payed_amount)))])])]}},{key:"header.due_amount",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.due_amount)))])])]}},{key:"header.payed_comision",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.payed_comision)))])])]}},{key:"header.no_payed_comision",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.no_payed_comision)))])])]}},{key:"header.discounted_chargeback",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.discounted_chargeback)))])])]}},{key:"header.no_discounted_chargeback",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.no_discounted_chargeback)))])])]}},{key:"header.total_balance",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(("" + (header.text))))]),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totales.total_balance)))])])]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }