<template>
  <div>
    <div class="d-flex pt-4">
      <div class="col-sm-2">
        <v-select
          :items="typepays"
          v-model="onlyPositive"
          dense
          clearable
          item-text="label"
          item-value="value"
          label="Pays"
          outlined
        ></v-select>
      </div>
      <div class="col-sm-3">
        <template>
          <v-text-field
            v-model.trim="query"
              @input="query=$event.toUpperCase()"
            :label="'Search'"
            hide-details
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
          />
        </template>
      </div>
      <v-spacer></v-spacer>
      <div class="col-sm-3 d-flex justify-end">
        <v-btn text small color="primary" @click="getImportLogs">
          <v-icon>mdi-refresh</v-icon> Update</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>

    <v-data-table
      dense
      :loading="loading"
      :headers="headers"
      :items="logs"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="footerProps"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <div class="cursor-pointer">
          {{ formatDate(item.createdAt) }}
        </div>
      </template>
      <template v-slot:[`header.clientName`]="{ header }">
          {{ `${header.text} (${summary?summary.total_person:''})` }}
        </template>
      <template v-slot:[`item.clientName`]="{ item }">
        <div
          :class="item.patientUuid ? '' : 'red--text'"
          class="cursor-pointer"
          @click="showDetails(item)"
        >
          {{ item.clientName }}
        </div>
      </template>
      <template v-slot:[`item.selectedDate`]="{ item }">
        <div class="cursor-pointer">
          {{ item.monthByAgent + ", " + item.yearByAgent }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import moment from "moment";

export default {
  props: {
    uuid: String,
  },
  data() {
    return {
      loading: false,
      loadingSummary:false,
      summary:null,
      query: null,
      onlyPositive:null,
      headers: [
        { text: "Created At", width: 100, value: "createdAt", sortable: false },
        { text: "Name", width: 300, value: "clientName", sortable: false },
        { text: "Policy Number", width: 100, value: "policyNumber", sortable: false },
        { text: "Commission", width: 100, value: "commission", sortable: false },
        { text: "Month", width: 100, value: "monthOfBillFromDate", sortable: false },
        { text: "Coverage Type", width: 100, value: "coverageType", sortable: false },
        { text: "Lives", width: 100, value: "lives", sortable: false },
        { text: "Not Found Motive", width: 100, value: "notFoundMotive", sortable: false },
      ],
      typepays:[
        {label:'All',value:null},
        {label:'Positives',value:true},
        {label:'Negatives',value:false},
      ],
      logs: [],
      options: {},
      total: 0,
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getImportLogs();
      }
    },
    onlyPositive:{
      handler(){
        this.getImportLogs()
      }
    },
    query(val) {
      if (val == null || val == "") {
        this.getImportLogs();
      } else if (val.length >= 3 || val.length == 0) {
        this.getImportLogs();
      }
    },
  },
  methods: {
    getImportLogs() {
      this.loading = true;

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = {
        limit: itmper,
        offset: (page - 1) * itmper,
        paymentTaskUuid: this.uuid,
      };

      if (this.query != null) {
        body = { ...body, query: this.query };
      }
      if (body.query == "") {
        delete body.query;
      }
      if (this.onlyPositive != null) {
        body = { ...body, onlyPositive: this.onlyPositive };
      }
      getAPI
        .post("payment-tasks/filterListLogs", body)
        .then((res) => {
          this.total = res.data.count;
          this.logs = res.data.result;
          this.getSummary()
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getSummary(){
      this.loadingSummary=true;
      let body = {       
        paymentTaskUuid: this.uuid,
      };

      if (this.query != null) {
        body = { ...body, query: this.query };
      }
      if (body.query == "") {
        delete body.query;
      }
      if (this.onlyPositive != null) {
        body = { ...body, onlyPositive: this.onlyPositive };
      }
      getAPI.post('/payment-tasks/sumaryfilterListLogs',body).then(res=>{
        this.summary=res.data[0];
        this.loadingSummary=false;
      }).catch(error=>{
        this.loadingSummary=false;
        let mess = error.response.data.message.toString();
        notifyError(error.response.data, `An error occurred: ${mess}`);
      })
    },

    closeDialog() {
      this.$emit("CloseDialog");
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    showDetails(value) {
      if (value.patientUuid) {
        this.$router.push(`/healths/details/${value.patientUuid}`);
      }
    },
  },
};
</script>
<style lang=""></style>
