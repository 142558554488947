var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-sm-3 pt-4"},[_c('v-select',{attrs:{"items":_vm.typeActives,"disabled":_vm.loading,"dense":"","item-text":"label","item-value":"value","label":"Active Status","outlined":""},model:{value:(_vm.onlyActive),callback:function ($$v) {_vm.onlyActive=$$v},expression:"onlyActive"}})],1),_c('div',{staticClass:"col-sm-3 pt-4"},[_c('v-text-field',{attrs:{"dense":"","disabled":_vm.loading,"outlined":"","hide-details":"","label":"by Policy Number","prepend-inner-icon":"mdi-magnify","clearable":""},on:{"input":function($event){_vm.policyNumber=$event.toUpperCase()}},model:{value:(_vm.policyNumber),callback:function ($$v) {_vm.policyNumber=$$v},expression:"policyNumber"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('v-switch',{attrs:{"label":"No Imported Month","loading":_vm.loading,"disabled":_vm.loading,"inset":""},on:{"change":_vm.getPayments},model:{value:(_vm.notImportedMonth),callback:function ($$v) {_vm.notImportedMonth=$$v},expression:"notImportedMonth"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","disabled":_vm.loading,"color":"primary","text":""},on:{"click":_vm.openFilters}},[_c('v-icon',[_vm._v("mdi-filter-variant")]),_vm._v(" Filters")],1),_c('v-btn',{attrs:{"text":"","small":"","disabled":_vm.loading,"color":"primary"},on:{"click":_vm.getPayments}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Update")],1)],1),[_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.payments,"options":_vm.options,"server-items-length":_vm.total,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.payment",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"fill-width background-green pa-1"},[_c('div',{staticClass:"text--center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticClass:"fill-width",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" Monthly Fee ")]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" Over rate ")]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" Bond ")])],1)],1)]}},{key:"header.chargeBack",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"fill-width background-red pa-1"},[_c('div',{staticClass:"text--center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticClass:"fill-width",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" Monthly Fee ")]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" Over rate ")]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" Bond ")])],1)],1)]}},{key:"header.balance",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"fill-width background-orange pa-1"},[_c('div',{staticClass:"text--center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticClass:"fill-width",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_vm._v(" Monthly Fee ")]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_vm._v(" Over rate ")]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_vm._v(" Bond ")]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_vm._v(" Amount ")]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_vm._v(" Range ")])],1)],1)]}},{key:"header.totalmembers",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(((header.text) + "(" + _vm.totalMembers + ")"))+" ")]}},{key:"item.polizestatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 cursor-pointer",style:({ color: _vm.colorShow(item) })},[_vm._v(" "+_vm._s(_vm.statusShow(item))+" ")])]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 cursor-pointer",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" "+_vm._s(item.fullname)+" ")])]}},{key:"item.paymentstatus",fn:function(ref){
var item = ref.item;
return [(item.paymentstatus)?[_c('span',{staticClass:"font-weight-bold",style:({ color: _vm.colorShowPaymentStatus(item.paymentstatus) })},[_vm._v(_vm._s(_vm.ToshowPaymentStatus(item.paymentstatus)))])]:_vm._e()]}},{key:"item.effectivedate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.effectivedate ? _vm.showEffective(item.effectivedate) : "-"))])]}},{key:"item.cancelleddate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.cancelleddate ? _vm.formatDate(item.cancelleddate) : "-"))])]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [(item)?[_c('v-row',{staticClass:"fill-width fill-height background-green",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.pay_monthly_fee))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.pay_overate))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.pay_bonus))+" ")])],1)]:_vm._e()]}},{key:"item.chargeBack",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"fill-width fill-height background-red",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.chargeback_monthly_fee))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.chargeback_pay_overate))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.chargeback_pay_bonus))+" ")])],1)]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"fill-width fill-height background-orange",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"3"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance_monthly_fee))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance_overate))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance_bonus))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance_amount))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"white"}},on),[_vm._v(_vm._s(_vm.getRange(item).icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getRange(item).tooltip))])])],1)],1)]}}],null,true)})]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }